




































import { showHelpScoutBeacon } from "@/core";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class HowDoWeCalcThisModal extends Vue {
  openBeacon(): void {
    showHelpScoutBeacon();
    (window as any).zE("messenger", "open");
  }
}
